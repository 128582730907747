import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'
import firebase from 'firebase'
import aliases from './aliases.json';
import aliasCollective from './aliasCollective.json';

const Home = () => (
  <div>
    <h2>Home</h2>
    <p>
      This is the home page!
    </p>
  </div>
)

const About = () => (
  <div>
    <h2>About</h2>
    <p>
      This is the about page!
    </p>
  </div>
)

const Contact = ({ match }) => (
  <div>
    <h2>Contact</h2>
    <p>
      This is the contact us page!
    </p>
  </div>
)

const Current = (props) => {
  window.location = 'https://a.currents.fm'
  return <div></div>
}

const redirectTo = url => {
  window.location = url;
}

const redirectLink = async (id) => {
  const db = firebase.firestore();
  const ldoc = await db.collection('shortlinks').doc(id).get();
  const ldata = await ldoc.data();
  const url = ldata.url;
  redirectTo(url);
}

const App = () => (
  <Router>
    <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
      <img src='./banner_large.png' style={{width: 480, height: 240, objectFit: 'cover'}} />
      <Route exact path="/" render={(props) => {
        //console.log
        if (process.env.REACT_APP_SPACE === 'COMMON') {
          redirectTo(`https://a.currents.fm/common`)
         }else {
        redirectTo(`https://a.currents.fm/`)
         }
        return <div />
      }} />
      <Route path="/current/:blogID" render={(props) => {
        //console.log('
        redirectTo(`https://a.currents.fm/current/${props.match.params.blogID}`)
        return <div />
      }} />
      <Route path="/common-nwd" render={(props) => {
        //console.log('
        redirectTo(`https://a.currents.fm/common`)
        return <div />
      }} />
      <Route path="/list/:collectionID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/collection/${props.match.params.collectionID}`)
        return <div />
      }} />
      <Route path="/common/performance/:performanceID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/common/performance/${props.match.params.performanceID}`)
        return <div />
      }} />
      <Route path="/common/event/:eventID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/common/event/${props.match.params.eventID}`)
        return <div />
      }} />
      <Route path="/common/live/:eventID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/common/live/${props.match.params.eventID}`)
        return <div />
      }} />
      <Route path="/common/planet/:collectiveID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/common/planet/${props.match.params.collectiveID}`)
        return <div />
      }} />
      <Route path="/post/:postID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/post/${props.match.params.postID}`)
        return <div />
      }} />
      <Route path="/album/:albumID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/album/${props.match.params.albumID}`);
        //window.location.assign(`https://a.currents.fm/album/${props.match.params.albumID}`)
        return <div />
      }} />
      <Route path="/track/:id" render={(props) => {
        //console.log
        console.log(props.location.search)
        redirectTo(`https://a.currents.fm/album/${props.match.params.id}${props.location.search}`);
        //window.location.assign(`https://a.currents.fm/album/${props.match.params.albumID}`)
        return <div />
      }} />
      <Route path="/link/:id" render={(props) => {
        //console.log
        console.log(props.location.search)
        redirectLink(props.match.params.id);
        //window.location.assign(`https://a.currents.fm/album/${props.match.params.albumID}`)
        return <div />
      }} />
      <Route path="/sc/:scID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/soundcloud/${props.match.params.scID}`)
        return <div />
      }} />
      <Route path="/archive/:name" render={(props) => {
        //console.log
        //redirectTo(`https://common.currents.fm/archive/${props.match.params.name}`)
        redirectTo(`https://archive.currents.fm/${props.match.params.name}`)
        return <div />
      }} />
      <Route path="/a/:name" render={(props) => {
        //console.log
        redirectTo(`https://archive.currents.fm/${props.match.params.name}`)
        return <div />
      }} />
      <Route path="/scu/:scuID" render={(props) => {
        //console.log
        redirectTo(`https://a.currents.fm/scu/${props.match.params.scuID}`)
        return <div />
      }} />
      <Route path="/bandcamp/:url" render={(props) => {
        //console.log
        let bcURL = props.location.search.split("?url=")[1]
        console.log(bcURL)
        redirectTo(`https://a.currents.fm/bc?${bcURL}`)
        return <div />
      }} />
      <Route path="/bc/:url" render={(props) => {
        //console.log
        let bcURL = props.location.search.split("?url=")[1]
        console.log(bcURL)
        redirectTo(`https://a.currents.fm/bc?${bcURL}`)
        return <div />
      }} />
      <Route path="/c/:name" render={(props) => {
        let name = props.match.params.name
        console.log(aliases[name])
        if (aliases[name])
          redirectTo(`https://a.currents.fm/current/${aliases[name]}`)
        return <div />
      }} />
      <Route path="/collective/:collectiveID" render={(props) => {
        let collectiveID = props.match.params.collectiveID
        //console.log(aliases[name])
        //if (aliases[name])
        redirectTo(`https://a.currents.fm/collective/${collectiveID}`)
        return <div />
      }} />
      <Route path="/home/:artistID" render={(props) => {
        let artistID = props.match.params.artistID
        //console.log(aliases[name])
        //if (aliases[name])
        redirectTo(`https://a.currents.fm/home/${artistID}`)
        return <div />
      }} />
      <Route path="/g/:collectiveID" render={(props) => {
        let collectiveID = props.match.params.collectiveID
        //console.log(aliases[name])
        //if (aliases[name])
        redirectTo(`https://a.currents.fm/g/${collectiveID}`)
        return <div />
      }} />
      <Route path="/covid" render={(props) => {
        let collectiveID = props.match.params.collectiveID
        //console.log(aliases[name])
        //if (aliases[name])
        redirectTo(`https://www.notion.so/9705ad3bd9f54000be110b0c0dac744e`)
        return <div />
      }} />
     
      <Route path="/about" render={(props) => {
        let collectiveID = props.match.params.collectiveID
        //console.log(aliases[name])
        //if (aliases[name])
        redirectTo(`https://a.currents.fm/about`)
        return <div />
      }} />
      <Route path="/contact" component={Contact}/>
      
    </div>
  </Router>
)
export default App;